import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Navigate, useLocation } from "react-router-dom";
import { KuzzleContext } from "../App";
import {
  getSubscriptionDetails,
  updateSubscription,
} from "../features/Auth/authSlice";

const PrivateRoute = ({ Component }) => {
  const dispatch = useDispatch();
  const { kuzzleSocket: kuzzle } = useContext(KuzzleContext);
  const auth = useSelector((state) => state.auth);
  const [room, setRoom] = useState(null);
  const loc = useLocation();

  useEffect(() => {
    if (auth.isAuth) {
      (async () => {
        const filters = {
          equals: {
            username: auth?.user?.username,
          },
        };

        const roomId = await kuzzle.realtime.subscribe(
          "bianat",
          "users",
          filters,
          (notification) => {
            const { result } = notification;

            if (result._source?.subscription) {
              dispatch(updateSubscription(result._source?.subscription));
              dispatch(getSubscriptionDetails());
            }
          }
        );

        setRoom(roomId);
      })();

      return async () => {
        if (room) {
          await kuzzle.realtime.unsubscribe(room);
        }
      };
    }
  }, [auth.isAuth]);

  // CHANGES DONE HERE

  if (!auth.isAuth) {
    return <Navigate to="/" replace={true} />;
  }

  if (auth.isAuth && auth.user?.subscription === "free") {
    if (loc.pathname !== "/settings") {
      return <Navigate to="/settings" replace />;
    }
  }
  return <Component />;
};

export default PrivateRoute;
