import { Content } from "antd/lib/layout/layout";
import BianatHeader from "../../components/BianatHeader";
import HighLights from "../home/components/Highlights";

//////////////////////

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../utils/firebase/config";
const AddOpenPosition = () => {
  const [isGuideOpen, setIsGuideOpen] = useState(false);

  // DATA
  const [formData, setFormData] = useState({
    symbol: "",
    buyDate: "",
    buyPrice: "",
    stopLoss: "",
    avgVol: "",
    ESPDate: "",
    time: "",
    PL: "",
    actions: "",
  });

  // ESSENTIALS
  const { t, i18n } = useTranslation();
  const currentTheme = useSelector((state) => state.currentTheme.currentTheme);

  // VALIDATION

  const validate = () => {
    for (const key in formData) {
      if (formData[key].trim() === "") {
        return false; 
      }
    }
    return true; 
  };
  

  // HANDLES
  const handleSubmit = async () => {
    const docRef = collection(db, "openPositions");

    if(validate()){
      try {
        const response = await addDoc(docRef, formData);
        alert("Data Submitted Successfully!")
      } catch (error) {
        console.log(error);
        alert("Error In Submitting Data")
      }
    }else{
      alert("Please fill all the fields");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // RETURNING UI
  return (
    <div className="h-full">
      <BianatHeader setIsGuideOpen={setIsGuideOpen} followUpPage={"true"} />
      <Content
        className={`landing-content min-h-[100vh] ${
          i18n.language === "en" ? "font-loader-en" : "font-loader"
        } ${currentTheme === "Dark" && "dark-skin"}`}
      >
        <div className="live-update-toolbar">
          <HighLights />
        </div>
        <div className="w-[80%] m-auto py-10 flex flex-col gap-8 ">
          <h1 className="text-[30px]">{t("adminUploadData.addOpenPosition")}</h1>
          <div className="flex flex-col gap-10">
            <div className="flex gap-10 items-center">
              <label className="w-[150px] font-bold">{t("openPosition.symbol")} : </label>
              <select
                name="symbol"
                value={formData.symbol}
                onChange={handleChange}
                className="bg-slate-800 rounded-lg p-3 outline-none w-[250px]"
              >
                <option value="" disabled>
                  Select Symbol
                </option>
                <option value="test">
                  Test
                </option>
                {/* Add options here */}
              </select>
            </div>
            <div className="flex gap-10 items-center">
              <label className="w-[150px] font-bold">{t("openPosition.BuyPrice")} : </label>
              <input
                type="date"
                name="buyDate"
                value={formData.buyDate}
                onChange={handleChange}
                className="bg-slate-800 rounded-lg p-2 outline-none w-[250px]"
              />
              <input
                type="text"
                name="buyPrice"
                value={formData.buyPrice}
                onChange={handleChange}
                placeholder="Enter Price"
                className="bg-slate-800 rounded-lg p-2 outline-none w-[250px]"
              />
            </div>
            <div className="flex gap-10 items-center">
              <label className="w-[150px] font-bold">{t("openPosition.stopLoss")} : </label>
              <input
                type="text"
                name="stopLoss"
                value={formData.stopLoss}
                onChange={handleChange}
                placeholder="Price"
                className="bg-slate-800 rounded-lg p-2 outline-none w-[250px]"
              />
              <input
                type="text"
                name="stopLossPercent"
                value={formData.stopLossPercent}
                onChange={handleChange}
                placeholder="Percentage (%)"
                className="bg-slate-800 rounded-lg p-2 outline-none w-[250px]"
              />
            </div>
            <div className="flex gap-10 items-center">
              <label className="w-[150px] font-bold">Avg Vol (K) : </label>
              <input
                type="text"
                name="avgVol"
                value={formData.avgVol}
                onChange={handleChange}
                placeholder="Enter Average Volume"
                className="bg-slate-800 rounded-lg p-2 outline-none w-[250px]"
              />
            </div>
            <div className="flex gap-10 items-center">
              <label className="w-[150px] font-bold">Est. ESP Date : </label>
              <input
                type="date"
                name="ESPDate"
                value={formData.ESPDate}
                onChange={handleChange}
                className="bg-slate-800 rounded-lg p-2 outline-none w-[250px]"
              />
            </div>
            <div className="flex gap-10 items-center">
              <label className="w-[150px] font-bold">{t("openPosition.time")} : </label>
              <select
                name="time"
                value={formData.time}
                onChange={handleChange}
                placeholder={"select TIme"}
                className="bg-slate-800 rounded-lg p-3 outline-none w-[250px]"
              >
                <option value="" disabled>
                  Select Time
                </option>
                <option value="test">
                  Test
                </option>
                {/* Add options here */}
              </select>
            </div>
            <div className="flex gap-10 items-center">
              <label className="w-[150px] font-bold">{t("openPosition.P&L")} (%) : </label>
              <input
                type="text"
                name="PL"
                value={formData.PL}
                onChange={handleChange}
                placeholder="Enter P&L %"
                className="bg-slate-800 rounded-lg p-2 outline-none w-[250px]"
              />
            </div>
            <div className="flex gap-10 items-center">
              <label className="w-[150px] font-bold">{t("openPosition.action")} : </label>
              <select
                name="actions"
                value={formData.actions}
                onChange={handleChange}
                className="bg-slate-800 rounded-lg p-3 outline-none w-[250px]"
              >
                <option value="" disabled>
                  Select Action
                </option>
                <option value="test">
                  Test
                </option>
                {/* Add options here */}
              </select>
            </div>
            <div className="self-end w-fit">
              <button
                className="p-2 bg-[#004f86] rounded-md"
                onClick={handleSubmit}
              >
                {t("adminUploadData.addOpenPosition")}
              </button>
            </div>
          </div>
        </div>
      </Content>
    </div>
  );
};

export default AddOpenPosition;
