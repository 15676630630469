
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Firebase configuration
const firebaseConfig = {

  apiKey: "AIzaSyDTNbs1Clv4gt15PzL08EwIyw0o3pGkN5s",
  authDomain: "bianat-56ef3.firebaseapp.com",
  projectId: "bianat-56ef3",
  storageBucket: "bianat-56ef3.appspot.com",
  messagingSenderId: "1069718418185",
  appId: "1:1069718418185:web:7a2d800fea0a160fb219d8",
  // measurementId: import.meta.env.VITE_MEASUREMENT_ID,
};

  

// Initialize Firebase and Firebase Storage
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);


// Firebase Authentication
export const auth = getAuth(app);
// Cloud Firestore
export const db = getFirestore(app);
//  Firebase Storage
export { storage };